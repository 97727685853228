<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";

/**
 * Lending component
 */
export default {
  page: {
    title: "Pages List",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Pages List",
      items: [
        {
          text: "Pages",
          href: "#",
        },
        {
          text: "Pages List",
          active: true,
        },
      ],
      loading: true,
      fields: [
        "Index",
        {
          key: "Page_Name",
          value: "sub_type",
        },
        {
          key: "status",
          value: "status",
        },
        {
          key: "edit",
          value: "edit",
        },
        {
          key: "view",
          value: "view",
        },
      ],
    };
  },
  mounted() {
    this.myProvider();
  },
  methods: {
    async myProvider() {
      var res = await ApiClass.getRequest("pages/get", true);
      if (res.data.status_code == 1) {
        this.loading = false;
        return res.data.data;
      } else {
        this.loading = false;
      }
    },
    async updateStatus(data) {
      var param = { status: data.status == true ? 1 : 0 };
      var response = await ApiClass.updateFormRequest(
        "admin/pages/update/" + data.id,
        true,
        param
      );
      if (response.data.status_code == 1) {
        Swal.fire("Updated!", response.data.message, "success");
      } else {
        Swal.fire("Error!", response.data.message, "error");
      }
    },
    viewPage(response) {
      this.$router.push({
        name: "PageShow",
        params: { data: response.content },
      });
    },
    editPage(response) {
      this.$router.push({
        name: "PageEdit",
        params: { data: response },
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Pages List</h4>

            <div v-if="!loading">
              <b-table
                striped
                hover
                :items="myProvider"
                :fields="fields"
                :busy="loading"
                ref="table"
                show-empty
                sortable="false"
              >
                <template #empty>
                  <div class="d-flex justify-content-center mb-3">
                    No Record Found
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <!-- <strong>Loading...</strong> -->
                  </div>
                </template>

                <!-- A virtual column -->
                <template #cell(Index)="data">
                  {{ data.index + 1 }}
                </template>

                <template v-slot:cell(Page_Name)="data">
                  <div>
                    {{ data.item.sub_type }}
                  </div>
                </template>

                <template v-slot:cell(status)="data">
                  <div class="form-check form-switch form-switch-md mb-3">
                    <input
                      v-model="data.item.status"
                      class="form-check-input"
                      type="checkbox"
                      id="SwitchCheckSizemd"
                      @change="updateStatus(data.item)"
                    />
                  </div>
                </template>

                <template v-slot:cell(edit)="data">
                  <div>
                    <span @click="editPage(data.item)"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        style="
                          fill: #556ee6;
                          cursor: pointer;
                          transform: ;
                          msfilter: ;
                        "
                      >
                        <path
                          d="m7 17.013 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58zM9 13.417l6.03-5.973 1.586 1.586-6.029 5.971L9 15.006v-1.589z"
                        ></path>
                        <path
                          d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"
                        ></path></svg
                    ></span>
                  </div>
                </template>

                <template v-slot:cell(view)="data">
                  <div>
                    <span @click="viewPage(data.item)"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        style="
                          fill: #556ee6;
                          cursor: pointer;
                          transform: ;
                          msfilter: ;
                        "
                      >
                        <path
                          d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 11c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z"
                        ></path>
                        <path
                          d="M12 10c-1.084 0-2 .916-2 2s.916 2 2 2 2-.916 2-2-.916-2-2-2z"
                        ></path></svg
                    ></span>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>


<style scoped>
.front_image img,
.back_image img {
  width: 150px;
  height: 120px;
}
.succ_btn {
  float: right;
  clear: both;
  margin: 20px 0px;
}
.clr-red {
  color: red;
}
.success {
  color: green;
  font-weight: 600;
  font-size: 14px;
}
</style>